import { formatPrice } from '../libs/utils/string'

export default (products, isVMSupported) =>
  products.reduce(
    (
      acc,
      {
        category,
        onSale,
        isCustomizable,
        isEngravable,
        price,
        mfpartnumber,
        upc,
        isFindInStore,
        isEmailMeWhenAvailable,
        isOutOfStock,
        isStella,
        catentryId,
        brand,
        img,
        pdpURL,
        modelName,
        localizedColorLabel,
        FrameTechnology,
        LensUPC,
        LensTechnology,
        LensAntiblue,
        LensReflective,
        LensTransition,
        LensDesign,
        LensType,
        LensIndex,
        LensBrand,
        LensTreatment,
        LensPolarized,
        ModelCode,
        Lens,
        Frame,
        Case,
        Cloth,
        Units,
        InsuranceAmount,
        InsuranceCode,
        InsuranceBenefits,
        Warranty,
        TaxRate,
        CancelledUnits,
        CancelledAmount,
        lifecycle,
        Status,
        isLoyaltyItem,
        productCategory,
      }
    ) => {
      Status = category
      const priceFull = onSale && onSale.originalPrice
      if (!isOutOfStock) {
        Status = 'Available'
      } else {
        if (lifecycle && lifecycle.toLowerCase() === 'active') Status = 'Out-of-stock'
        else {
          if (category && category.toLowerCase() === 'exclusive') Status = 'SoldOut'
          else Status = 'Not-for-sale'
        }
      }
      const availableAlternatives = []
      if (isFindInStore) {
        availableAlternatives.push('FindStore')
      }
      if (isEmailMeWhenAvailable) {
        availableAlternatives.push('EmailMeWhenAvailable')
      }

      let frameColor = ''
      let lensColor = ''
      if (localizedColorLabel) {
        let localizedSplittedColorLabel = localizedColorLabel.split('/')
        frameColor = localizedSplittedColorLabel[0]
        lensColor = localizedSplittedColorLabel[1]
      }

      const OosOptions = isOutOfStock ? availableAlternatives.join(',') : ''
      const isLoyaltyActive = sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
      const normalizedVal = productCategory === 'Accessories' ? productCategory.toUpperCase() : productCategory

      const newObj = {
        Status: Status,
        OosOptions,
        Vm_IsUpcSupported: isVMSupported,
        Conf_IsUpcSupported: '' + Number(isCustomizable || isEngravable),
        Category: isStella ? 'Electronics' : normalizedVal,
        Type: 'STD',
        Price: formatPrice(price.substring(1)),
        PriceFull: priceFull
          ? formatPrice(priceFull.substring(1))
          : formatPrice(price.substring(1)),
        Sku: mfpartnumber,
        MoCo: null,
        $productindex$: catentryId,
        Preorder: '0',
        Visibility: 'Public',
        Bagdes: '',
        Brand: brand,
        Image: img,
        Url: pdpURL,
        ModelName: modelName ? modelName : '',
        Engraving: '',
        Shape: '',
        FrameColor: frameColor,
        LensColor: lensColor,
        FrameTechnology: FrameTechnology ? FrameTechnology : '',
        LensUPC: LensUPC ? LensUPC : '',
        LensTechnology: LensTechnology ? LensTechnology : '',
        LensAntiblue: LensAntiblue ? LensAntiblue : '',
        LensReflective: LensReflective ? LensReflective : '',
        LensTransition: LensTransition ? LensTransition : '',
        LensDesign: LensDesign ? LensDesign : '',
        LensType: LensType ? LensType : '',
        LensIndex: LensIndex ? LensIndex : '',
        LensBrand: LensBrand ? LensBrand : '',
        LensTreatment: LensTreatment ? LensTreatment : '',
        LensPolarized: LensPolarized ? LensPolarized : '',
        ModelCode: ModelCode ? ModelCode : '',
        Lens: Lens ? Lens : '',
        Case: Case ? Case : '',
        Cloth: Cloth ? Cloth : '',
        Units: Units ? Units : '',
        InsuranceAmount: InsuranceAmount ? InsuranceAmount : '',
        InsuranceCode: InsuranceCode ? InsuranceCode : '',
        InsuranceBenefits: InsuranceBenefits ? InsuranceBenefits : '',
        Warranty: Warranty ? Warranty : '',
        TaxRate: TaxRate ? TaxRate : '',
        CancelledUnits: CancelledUnits ? CancelledUnits : '',
        CancelledAmount: CancelledAmount ? CancelledAmount : '',
        LoyaltyExclusive: isLoyaltyActive ? (isLoyaltyItem ? 'Y' : 'N') : '',
        FrameType: '',
      }
      const data = {
        [upc]: newObj,
      }

      return { ...acc, ...data }
    },
    {}
  )
